.App {
    text-align: center;
    background-color: #FFFFFF;
    padding: 2px;
    padding-right: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FonteAnuncio {
    margin: 0;
    margin-bottom: 8px;
    text-align: left;
    font-size: small;
    font-weight: bold;
    color: dimgray;
}

.FonteAnuncio_Mob {
    margin: 0;
    margin-bottom: 8px;
    text-align: left;
    font-size: x-small;
    font-weight: bold;
    color: dimgray;
}

.FonteDicas {
    margin: 0;
    text-align: left;
    font-size: x-small;
    font-weight: normal;
    color: black;
}

.FonteDicas_Mob {
    margin: 0;
    text-align: left;
    font-size: xx-small;
    font-weight: normal;
    color: black;
}

.FonteTitulos {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;
    font-size: medium;
    font-weight: normal;
    color: black;
}

.FonteTitulos_Mob {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;
    font-size: small;
    font-weight: normal;
    color: black;
}

.FontePrompts {
    margin: 0;
    margin-bottom: 8px;
    text-align: left;
    font-size: small;
    font-weight: normal;
    color: black;
}

.FontePrompts_Mob {
    margin: 0;
    margin-bottom: 8px;
    text-align: left;
    font-size: x-small;
    font-weight: normal;
    color: black;
}

.IconeAnuncio {
    width: 26px;
    height: 26px;
}

.IconeAnuncio_Mob {
    width: 18px;
    height: 18px;
}
